import styled from 'styled-components'
import breakpoints from '../../../../styles/breakpoints'

import imageBgMd from '../../../assets/images/carreirasHeroMd.png'
import imageBgLg from '../../../assets/images/carreirasHeroLg.png'
import imageBgXl from '../../../assets/images/carreirasHeroXl.png'

export const Section = styled.section`
  @media(min-width: ${breakpoints.md}) {
    display: flex;
    align-items: center;
    min-height: 650px;
    background: url(${imageBgMd});
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media(min-width: ${breakpoints.lg}) {
    min-height: 650px;
    background: url(${imageBgLg});
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media(min-width: ${breakpoints.xl}) {
    min-height: 650px;
    background: url(${imageBgXl});
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media(min-width: ${breakpoints.xxl}) {
    background-size: cover;
  }
`
