import styled from 'styled-components'
import { orange } from '../../../styles/colors'
import breakpoints from '../../../styles/breakpoints'
import { Link } from 'gatsby'

export const NotFoundJobs = styled.div`
  p, span {
    font-family: 'Sora';
  };
  position: relative;
  top: 40px;
`

export const BtnOrange = styled(Link)`
  border: 1px solid ${orange.extra};
  color: ${orange.extra};
  width: 380px;
`

export const JobContent = styled.div`
  @media (min-width: ${breakpoints.md}) {
    border-top: 1px solid #DEDFE4;
    padding-top: 32px;
  }

  .category {
    font-size: 24px;
    line-height: 30px;
    
    @media(min-width: ${breakpoints.md}) {
      font-size: 20px;
      line-height: 25px;
    }
    @media(min-width: ${breakpoints.lg}) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .link {
    color: #161616 !important;
    display: block;
    
    & + a {
      margin-top: 16px;
    }
  }
`
export const CardJob = styled.div`
  padding: 16px;
  border: 1px solid #DEDFE4;
  border-radius: 8px;

  &:hover {
    border: 1px solid #FF7A00;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 16px 44px;
  }

  .title {
    text-transform: capitalize;
  }

  .label {
    display: block;
    padding: 2px 4px;
    background: #F5F6FA;
    border-radius: 4px;
    font-weight: 700;
    font-size: 10px;
    color: #6A6C72;
    text-transform: capitalize;

    & + span {
      margin-left: 8px;
    }
  }
`
