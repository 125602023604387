import React, { useState, useEffect } from 'react'

// Hooks
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

// shared
import removeAcento from '../../../shared/removeAcentos'

// Components
import LoadingIndicatorComponent from '../../../components/LoadingIndicator/index'
import OrangeIcon from '../../../components/MarkdownIcon/OrangeDsIcon'

import { apiData, pageProps } from './types'

import { NotFoundJobs, BtnOrange, JobContent, CardJob } from './style'

const JobList = ({ searchText, jobsLocation, area, JobsContent }: pageProps) => {
  const [ categories, setCategories ] = useState<string[]>([])
  const [ sendDatalayerEvent ] = useDataLayer()

  const notFound = (
    <NotFoundJobs className='d-flex justify-content-center'>
      <div className='col-8 d-flex flex-column align-items-center pb-5'>
        <OrangeIcon size='XL' color='#DEDFE4' icon='attention' />
        <p className='fs-24 text-center text-grayscale-500 fw-700 mt-2'>
          <span className='d-block'>Infelizmente não encontramos nenhuma vaga</span> cadastrada com base em sua busca.
        </p>
        <BtnOrange
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_2',
              element_action: 'click button',
              element_name: 'Cadastra-se em nosso Banco de Talentos',
              section_name: 'Encontre a melhor oportunidade no Inter',
              redirect_url: 'https://boards.greenhouse.io/inter/jobs/4052543005?gh_jid=4052543005',
            })
          }}
          to='https://boards.greenhouse.io/inter/jobs/4052543005?gh_jid=4052543005'
          className='fs-14 rounded-8 px-4 py-2 text-center fw-700'
        >
          Cadastra-se em nosso Banco de Talentos
        </BtnOrange>
      </div>
    </NotFoundJobs>
  )

  useEffect(() => {
    if (JobsContent.length > 0) {
      const listCategories = JobsContent.map((item) => item.metadata.filter((item) => removeAcento(item.name).toLowerCase() === 'area')
        .reduce((arr, item) => arr.concat(item)))
      const categoriesName = listCategories && listCategories.filter((item) => removeAcento(item.name).toLowerCase() === 'area')
        .map(item => item.value ? item.value[0] : 'Candidate Pool')
        .filter(item => item !== null)
      const categoryList = categoriesName && categoriesName.filter((item: string, index: number) => categoriesName.indexOf(item) === index)
      // const newCategoryList = categoryList.map((item) => item || 'Candidate Pool')
      setCategories(categoryList)
    }
  }, [ searchText, jobsLocation, area, JobsContent ])

  if (JobsContent?.length > 0) {
    if (searchText) {
      const lowreSearchText = searchText.toLowerCase()
      JobsContent = JobsContent?.filter((item) => item.title.toLowerCase().indexOf(lowreSearchText) > -1 || item.location.name.toLowerCase().indexOf(lowreSearchText) > -1 || item.metadata[1].value && item.metadata[1].value.toLowerCase().indexOf(lowreSearchText) > -1
      // || item.metadata[2].value && item.metadata[2].value[0].toLowerCase() === lowreSearchText
      )
    }

    if (jobsLocation) {
      JobsContent = JobsContent.filter((item: apiData) => {
        return jobsLocation.includes(item.location.name.toLowerCase())
      })
    }

    if (area) {
      JobsContent = JobsContent.filter((item) => {
        const category = item.metadata.find(item => removeAcento(item.name).toLowerCase() === 'area')
        return category.value && category.value[0] === area
      })
    }

    if (JobsContent?.length === 0) {
      return notFound
    }
  }

  return (
    <>
      {!JobsContent
        ? <div className='d-flex justify-content-center'>
          <LoadingIndicatorComponent />
        </div>
        : categories && categories.map((item: string) => (
          <JobContent className='mb-5' key={item}>
            <div className='row d-flex justify-content-between'>
              <div className='col-12 col-md-4 col-lg-3 col-xl-3'>
                <h3 className='text-grayscale--500 fw-600 category'>{item || 'Candidate Pool'}</h3>
              </div>

              <div className='col-12 col-md-8 col-lg-7'>
                {
                  JobsContent.map((obj) => {
                    return obj.metadata.filter((meta) => removeAcento(meta.name).toLowerCase() === 'area')
                      .filter((area) => area.value ? area.value[0] === item : item === 'Candidate Pool').map((job) => (
                        <a
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_2',
                              element_action: 'click button',
                              element_name: obj.title.includes('DEV IOS') && obj.title.replace('DEV IOS', 'Dev IOS') || obj.title.toLowerCase().replace('iii', 'III').replace('ii', 'II'),
                              section_name: 'Encontre a melhor oportunidade no Inter',
                              redirect_url: obj.absolute_url,
                            })
                          }}
                          className='link'
                          href={obj.absolute_url}
                          title={obj.title}
                          key={obj.id}
                        >
                          <CardJob>
                            <h4 className='fs-16 lh-20 fs-md-20 lh-md-25 text-grascale--500 fw-600 title'>
                              {obj.title.includes('DEV IOS') && obj.title.replace('DEV IOS', 'Dev IOS') || obj.title.toLowerCase().replace('iii', 'III').replace('ii', 'II')}
                            </h4>
                            <div className='d-flex align-items-center'>
                              <span className='label'>
                                {item}
                              </span>
                            </div>
                          </CardJob>
                        </a>
                      ))
                  })
                }
              </div>
            </div>
          </JobContent>
        ))}
    </>
  )
}

export default JobList
