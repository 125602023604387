import styled from 'styled-components'
import breakpoints from '../../../../styles/breakpoints'

import jobOpportunityBgSm from '../../../assets/images/jobOpportunityBgSm.png'
import jobOpportunityBgMd from '../../../assets/images/jobOpportunityBgMd.png'
import jobOpportunityBgLg from '../../../assets/images/jobOpportunityBgLg.png'
import jobOpportunityBgXl from '../../../assets/images/jobOpportunityBgXl.png'

export const Section = styled.section`
  min-height: 756px;
  background-color: #F5F6FA;
  background-image: url(${jobOpportunityBgSm});
  background-repeat: no-repeat;
  background-size: contain;
  
  @media (min-width: ${breakpoints.md}) {
    min-height: 718px;
    background-image: url(${jobOpportunityBgMd});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 646px;
    background-image: url(${jobOpportunityBgLg});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 646px;
    background-image: url(${jobOpportunityBgXl});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
  }
`
export const Button = styled.a`
  width: 100%;
  height: 48px;
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  background: #FF7A00;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  max-width: 578px;

  &:hover, &:focus {
    outline: none;
    text-decoration: none;
    color: #ffffff;
    opacity: 0.8;
  }
`
