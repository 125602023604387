import styled from 'styled-components'
import { grayscale, white } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

// Assets
import iconSearch from './images/icon-search.svg'

export default styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 1;
  
  @media(min-width: ${breakpoints.lg}) {
    margin-top: 35px;
    position: relative;
    right: 0;
    left: 0;
    z-index: 1;
  }

  input {
    border: none;
    border-radius: 8px;
    color: ${grayscale['400']};
    background-color: ${grayscale['100']};
    font-weight: 700;
    height: 52px;
    outline: 0;
    width: 100%;
    margin: 0 auto;
    display: block;

    &:placeholder-shown {
      background: url(${iconSearch}) no-repeat;
      background-position: 10px 20px;
      background-color: ${grayscale['100']};
      color: ${grayscale['500']};
      font-weight: 700;
      font-size: 14px;
    }
  }
`
