import React, { ChangeEvent } from 'react'

import { SelectFilter } from './style'

type pageProps = {
  locationJobUnique: string[];
  setJobsLocation: Function;
  occupationAreaUnique: string[];
  setArea: Function;
}

const SelectJobs = ({ locationJobUnique, setJobsLocation, occupationAreaUnique, setArea }: pageProps) => {
  const handleSelectLocation = (evt: ChangeEvent<HTMLSelectElement>) => {
    setJobsLocation(evt.target.value)
  }

  const handleSelectArea = (evt: ChangeEvent<HTMLSelectElement>) => {
    setArea(evt.target.value)
  }

  return (
    <SelectFilter className='row'>
      <div className='col-12 col-md-6 select px-xl-2 mb-3 mb-md-0'>
        <select onChange={(evt: ChangeEvent<HTMLSelectElement>) => handleSelectLocation(evt)}>
          <option value=''>Localização</option>
          {locationJobUnique?.map((item: string, index: number) => {
            return (
              <option key={index} className='location'>
                {item}
              </option>)
          })
          }
        </select>
      </div>
      <div className='col-12 col-md-6 select px-xl-2'>
        <select onChange={(evt: ChangeEvent<HTMLSelectElement>) => handleSelectArea(evt)}>
          <option value=''>Área de Atuação</option>
          {occupationAreaUnique?.map((item: string, index: number) => {
            return (
              <option key={index}>
                {item}
              </option>)
          })
          }
        </select>
      </div>
    </SelectFilter>
  )
}

export default SelectJobs
