import React, { useState, useLayoutEffect } from 'react'
import Img from 'gatsby-image'

import useWidth from '../../../../hooks/useWidth'

import pageQuery from '../../../pageQuery'

import { Section } from './style'

const WIDTH_MD = 768

const Hero = () => {
  const [ isMobile, setIsMobile ] = useState(true)

  const data = pageQuery()
  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          {
            isMobile && (
              <div className='col-12'>
                <Img fluid={data.carreirasHero.fluid} />
              </div>
            )
          }
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className={`fs-32 lh-40 fs-xl-48 lh-xl-60 fw-600 mb-2 mb-md-4 ${isMobile ? 'text-grayscale--500' : 'text-white'}`}>
              Venha criar o que simplifica a vida das pessoas!
            </h1>
            <p className={`fs-16 lh-19 fs-xl-18 lh-xl-22 ${isMobile ? 'text-grayscale--500' : 'text-white'}`}>
              O Inter está sempre crescendo, assim como nosso time #sanguelaranja.
              Se o que te motiva é trabalhar de forma integrada e inovadora, aqui é o seu lugar!
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
