import React from 'react'

// Hooks
import useDataLayer from '../../../../hooks/useDataLayer/dataLayerBody'

import { Section, Button } from './style'

const JobOpportunity = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-end align-items-lg-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-5'>
            <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-md-center text-lg-left'>
              <span className='d-xl-block'>Não encontrou </span>
              uma vaga?
            </h2>
            <p className='fs-18 lh-22 text-grayscale--500 text-md-center text-lg-left'>Cadastre seu currículo em nosso Banco de Talentos.</p>
            <Button
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_3',
                  element_action: 'click button',
                  element_name: 'Cadastre seu currículo',
                  section_name: 'Não encontrou uma vaga?',
                  redirect_url: 'https://boards.greenhouse.io/inter/jobs/4097248005?gh_jid=4097248005'
                })
              }}
              href='https://boards.greenhouse.io/inter/jobs/4097248005?gh_jid=4097248005'
              className='mx-auto'
              title='Cadastre seu currículo'
            >
              Cadastre seu currículo
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default JobOpportunity
