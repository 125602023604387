import React, { useState, useEffect } from 'react'
import QueryString from 'query-string'
import api from '../../services/api'

import { Helmet } from 'react-helmet'

// shared
import removeAcentos from '../../shared/removeAcentos'

// data
// import data from './assets/data/data.json'

// components
import Layout from '../../components/Layout'
import GeneralSearch from '../../components/GeneralSearch/index'
import SelectJobs from './SelectJobs/index'
import JobList from './JobList/index'

// Sections
import Hero from './sections/hero/_hero'
import JobOpportunity from './sections/jobOpportunity/_index'

// hooks
import useDomReady from '../../hooks/window/useDomReady'

// PageContext
import pageContext from '../pageContext.json'

import { apiData } from './types'

const Carreiras = () => {
  const [ queryString, setQueryString ] = useState('')
  const [ openJobs, setOpenJobs ] = useState([])
  const [ searchText, setSearchText ] = useState('')
  const [ area, setArea ] = useState()
  const [ jobsLocation, setJobsLocation ] = useState()

  const locationJob = openJobs.map((item: apiData) => item.location.name.toLowerCase())
  const locationJobUnique = locationJob.filter((item: apiData, index: number) => locationJob.indexOf(item) === index)
  const occupationArea = openJobs.filter((item: apiData) => item.id !== 4033028005)
    .map((item: apiData) => item.metadata.filter(item => removeAcentos(item.name).toLowerCase() === 'area').reduce((arr, item) => arr.concat(item)))
    .map(item => item.value && item.value[0]).filter(item => item !== null)
  const occupationAreaUnique = occupationArea?.filter((item: apiData, index: number) => occupationArea.indexOf(item) === index)
  const domReady = useDomReady()
  const JobsContent = openJobs

  useEffect(() => {
    api.get('/jobs')
      .then((response) => setOpenJobs(response.data.jobs))
      .catch((err) => {
        console.log('Ops! Ocorreu um erro' + err)
      })
  }, [])

  useEffect(() => {
    if (domReady) {
      setQueryString(QueryString.parse(window.location.href))
      setSearchText(queryString.q)
    }
  }, [ searchText, domReady ])

  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <section className='py-5'>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-8 col-lg-10 mb-5'>
              <h2 className='fs-32 lh-40 text-grayscale--500 fw-600 text-md-center mb-4'>Encontre a melhor oportunidade no Inter</h2>
              <GeneralSearch
                placeholderText='Procure por palavras-chave'
                setInputText={setSearchText}
                styles='col-12 px-0'
              />
              <SelectJobs
                locationJobUnique={locationJobUnique}
                setJobsLocation={setJobsLocation}
                occupationAreaUnique={occupationAreaUnique}
                setArea={setArea}
              />
            </div>

            <div className='col-12'>
              <JobList
                searchText={searchText}
                jobsLocation={jobsLocation}
                area={area}
                JobsContent={JobsContent}
              />
            </div>
          </div>
        </div>
      </section>
      <JobOpportunity />
    </Layout>
  )
}

export default Carreiras
