import React, { ChangeEvent, useRef, useState } from 'react'
import { useLocation } from '@reach/router' //

// Hooks
import useDataLayer from '../../hooks/useDataLayer/dataLayerBody'

// Style
import GeneralSearchStyle from './style'

type GeneralSearchProps = {
  initialValue?: string;
  styles?: string;
  setInputText: Function;
  placeholderText?: string;
}

const GeneralSearch = ({ initialValue, styles, setInputText, placeholderText }: GeneralSearchProps) => {
  const textInput = useRef(null)
  const [ searchText, setSearchText ] = useState(initialValue)
  const location = useLocation()
  const [ sendDatalayerEvent ] = useDataLayer()

  const ofertasPublicas = location.pathname.includes('/carreiras')

  function handleChange (evt: ChangeEvent<HTMLInputElement>) {
    const valueInput = evt.target.value
    setInputText(valueInput)
    setSearchText(valueInput)
    ofertasPublicas && window.history.pushState('', '', `/carreiras?&q=${encodeURIComponent(valueInput)}`)
    sendDatalayerEvent({
      section: 'dobra_2',
      element_action: 'click button',
      element_name: 'Busca',
      element_search: evt.target.value,
      section_name: 'Encontre a melhor oportunidade no Inter',
    })
  }

  return (
    <GeneralSearchStyle id='search-form' className={styles}>
      <input
        type='text'
        placeholder={placeholderText}
        className='pl-5 fs-16 fs-sm-14 lh-17'
        maxLength={150}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
        value={searchText}
        ref={textInput}
      />
    </GeneralSearchStyle>
  )
}

GeneralSearch.defaultProps = {
  initialValue: '',
  styles: '',
}

export default GeneralSearch
